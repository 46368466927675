<template>
  <app-page :toolbar="toolbar" :list="list" :creator="creator" />
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'Buy',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '60px',
        model: {
          name: undefined,
          status: undefined
        },
        fields: [
          { label: '名称', prop: 'name', placeholder: '请输入要搜索的名称' },
          {
            label: '状态',
            prop: 'status',
            placeholder: '请输入要搜索的状态',
            type: 'select',
            options: [
              {
                label: '全部',
                value: undefined
              },
              {
                label: '可用',
                value: 0
              },
              {
                label: '已用完',
                value: 1
              },
              {
                label: '已取消',
                value: 2
              }
            ]
          }
        ]
      },
      list: {
        method: 'post',
        url: '/cosmetology/project/setmeal/buy',
        fields: [
          { label: '姓名', prop: 'userName', width: 160 },
          { label: '购买日期', prop: 'createTime', width: 220 },
          {
            label: '套餐名',
            prop: 'cosmetologyProjectName'
          },
          {
            label: '套餐单价',
            prop: 'unitPrice',
            width: 100,
            align: 'right',
            formatter(row) {
              return `￥${row.unitPrice}`;
            }
          },
          {
            label: '套餐总价',
            prop: 'totalPrice',
            width: 100,
            align: 'right',
            formatter(row) {
              return `￥${row.totalPrice}`;
            }
          },
          {
            label: '次数',
            prop: 'number',
            width: 100,
            align: 'right'
          },
          {
            label: '已使用次数',
            prop: 'usedNumber',
            width: 100,
            align: 'right'
          },
          {
            label: '未使用次数',
            prop: 'unusedNumber',
            align: 'right',
            width: 100
          },
          {
            label: '状态',
            prop: 'status',
            width: 100,
            align: 'right',
            colors: ['success', 'danger', 'info'],
            formatter(row) {
              const values = ['可用', '已用完', '已取消'];
              return values[row.status];
            }
          }
        ],
        action: false
      },
      creator: {
        width: '400px',
        title: '购买套餐',
        text: '购买套餐',
        size: 'small',
        labelWidth: '120px',
        submit: true,
        url: '/cosmetology/project/setmeal/buy/create',
        model: {
          userId: undefined,
          cosmetologyProjectSetmealId: undefined
        },
        rules: {
          userId: { required: true, message: '用户是必选的' },
          cosmetologyProjectSetmealId: {
            required: true,
            message: '套餐是必选的'
          }
        },
        fields: [
          {
            label: '用户',
            prop: 'userId',
            type: 'select',
            placeholder: '请输入用户',
            clearable: true,
            options: [],
            filterable: true,
            remote: true,
            // 远程搜索
            remoteMethod: this.getUsers,
            valueKey: 'id'
          },
          {
            label: '套餐',
            prop: 'cosmetologyProjectSetmealId',
            type: 'select',
            placeholder: '请选择套餐',
            clearable: true,
            options: [],
            mapper(item) {
              return {
                label: item.name,
                value: item.id
              };
            },
            url: '/cosmetology/project/setmeal/allList',
            method: 'post'
          }
        ]
      }
    };
  },
  methods: {
    async getUsers(query) {
      if (!query) {
        return;
      }
      const result = await request.post('/user/getAllList', {
        name: query,
        isActive: 1,
        isReg: 1
      });
      const options = result.data.records.map(item => {
        return {
          label: item.name,
          value: item.userid
        };
      });
      this.creator.fields[0].options = options;
    }
  }
};
</script>
